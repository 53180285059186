<template>
  <div>
    <Footer></Footer>
    <div class="content">
      <span class="title">
        <!-- 第九届“大唐杯”全国大学生新一代信息通信技术大赛（南区）全国总决赛成功举办 -->
        “挑战杯”全国大学生课外学术科技作品竞赛和中国大学生创业计划竞赛
      </span>

      <div class="contbox">
        <p class="text_t">
          挑战杯是“挑战杯”全国大学生系列科技学术竞赛的简称，是由共青团中央、中国科协、教育部和全国学联共同主办的全国性的大学生课外学术实践竞赛，竞赛官方网站为www.tiaozhanbei.net。“挑战杯”竞赛在中国共有两个并列项目，一个是“挑战杯”中国大学生创业计划竞赛，另一个则是“挑战杯”全国大学生课外学术科技作品竞赛。这两个项目的全国竞赛交叉轮流开展，每个项目每两年举办一届。
        </p><br>
        <div class="imgbox">
          <h4>“挑战杯”全国大学生课外学术科技作品竞赛:</h4>
        </div>
        <p class="text_t">
          “挑战杯”全国大学生课外学术科技作品竞赛（以下简称“‘挑战杯’竞赛”）是由共青团中央、中国科协、教育部、全国学联和地方政府共同主办，国内著名大学、新闻媒体联合发起的一项具有导向性、示范性和群众性的全国竞赛活动。自1989年首届竞赛举办以来，“挑战杯”竞赛始终坚持“崇尚科学、追求真知、勤奋学习、锐意创新、迎接挑战”的宗旨，在促进青年创新人才成长、深化高校素质教育、推动经济社会发展等方面发挥了积极作用，在广大高校乃至社会上产生了广泛而良好的影响，被誉为当代大学生科技创新的“奥林匹克”盛会。竞赛的发展得到党和国家领导同志的亲切关怀，江泽民同志为“挑战杯”竞赛题写了杯名，李鹏、李岚清等党和国家领导同志题词勉励。历经十届，“挑战杯”竞赛已经成为：
        </p>
        <p class="text_t">
          ——吸引广大高校学生共同参与的科技盛会。从最初的19所高校发起，发展到1000多所高校参与；从300多人的小擂台发展到200多万大学生的竞技场，“挑战杯”竞赛在广大青年学生中的影响力和号召力显著增强。
        </p>
        <p class="text_t">
          ——促进优秀青年人才脱颖而出的创新摇篮。竞赛获奖者中已经产生了两位长江学者，6位国家重点实验室负责人，20多位教授和博士生导师，70%的学生获奖后继续攻读更高层次的学历，近30%的学生出国深造。他们中的代表人物有：第二届“挑战杯”竞赛获奖者、国家科技进步一等奖获得者、中国十大杰出青年、北京中星微电子有限公司董事长邓中翰，第五届“挑战杯”竞赛获奖者、“中国杰出青年科技创新奖”获得者、安徽中科大讯飞信息科技有限公司总裁刘庆峰，第八届、第九届“挑战杯”竞赛获奖者、“中国青年五四奖章”标兵、南京航空航天大学2007级博士研究生胡铃心等。
        </p>
        <p class="text_t">
          ——引导高校学生推动现代化建设的重要渠道。成果展示、技术转让、科技创业，让“挑战杯”竞赛从象牙塔走向社会，推动了高校科技成果向现实生产力的转化，为经济社会发展做出了积极贡献。
        </p>
        <p class="text_t">
          ——深化高校素质教育的实践课堂。“挑战杯”已经形成了国家、省、高校三级赛制，广大高校以“挑战杯”竞赛为龙头，不断丰富活动内容，拓展工作载体，把创新教育纳入教育规划，使“挑战杯”竞赛成为大学生参与科技创新活动的重要平台。
        </p>
        <p class="text_t">
          ——展示全体中华学子创新风采的亮丽舞台。香港、澳门、台湾众多高校积极参与竞赛，派出代表团参加观摩和展示。竞赛成为两岸四地青年学子展示创新风采的舞台，增进彼此了解、加深相互感情的重要途径。
        </p><br>
        <div class="imgbox">
          <h4>  “挑战杯”中国大学生创业计划竞赛::</h4>
          <p class="text_t">
            创业计划竞赛起源于美国，又称商业计划竞赛，是风靡全球高校的重要赛事。它借用风险投资的运作模式，要求参赛者组成优势互补的竞赛小组，提出一项具有市场前景的技术、产品或者服务，并围绕这一技术、产品或服务，以获得风险投资为目的，完成一份完整、具体、深入的创业计划。
        </p>
        <p class="text_t">
          竞赛采取学校、省（自治区、直辖市）和全国三级赛制，分预赛、复赛、决赛三个赛段进行。
        </p>
        <p class="text_t">
          大力实施"科教兴国"战略，努力培养广大青年的创新、创业意识，造就一代符合未来挑战要求的高素质人才，已经成为实现中华民族伟大复兴的时代要求。作为学生科技活动的新载体，创业计划竞赛在培养复合型、创新型人才，促进高校产学研结合，推动国内风险投资体系建立方面发挥出越来越积极的作用。
        </p>
        <div class="img_box" data-v-606a818d=""></div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
  
  <script>
import Footer from "@/components/Footer/Footer.vue";
import Bottom from "@/components/Bottom/Bottom.vue";
import $ from "jquery";
export default {
  name: "Home",
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>
  
  <style scoped lang="scss">
@import "./Brief.scss";
</style>
  